<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="(isNew) ? title : item.user.name" back="Convention.Team.Members">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid || model.used" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item label="User" :grow="true" :value="item.user.name" />
			<app-content-summary-item v-if="item.badge" label="Badge" :value="item.badge.reference" />

		</app-content-summary>

		<app-content-box v-if="isNew">

			<app-input-account label="E-mail" type="team" :text="emailMessages" v-model="model.email" v-on:exists="onExistsChange" :validation="$v.model.email" />
			<app-input-text :disabled="$v.model.email.$invalid || model.exists" label="Firstname" placeholder="Enter firstname" v-model="model.firstname" :validation="$v.model.firstname" />
			<app-input-text :disabled="$v.model.email.$invalid || model.exists" label="Lastname" placeholder="Enter lastname" v-model="model.lastname" :validation="$v.model.lastname" />
			<app-input-toggle :disabled="$v.model.email.$invalid" label="Assign Badge" v-model="model.badgeGenerate" :validation="$v.model.badgeGenerate" />
			<app-input-select :disabled="$v.model.email.$invalid || !model.badgeGenerate" label="Badge Type" v-model="model.badgeType" :validation="$v.model.badgeType" placeholder="Select badge type" :options="badgeTypes" />

		</app-content-box>

		<app-content-box title="Convention access" icon="password" v-if="!isNew">

			<app-input-toggle label="Announcements" v-model="model.permissions.convention.announcements.access" />
			<app-input-toggle label="Badges" v-model="model.permissions.convention.badges.access" />

		</app-content-box>

		<app-content-box title="Service access" icon="password" v-if="!isNew">

			<app-input-toggle label="Registration" v-model="model.permissions.services.registration.access" />
			<app-input-toggle label="Schedule" v-model="model.permissions.services.schedule.access" />
			<app-input-toggle label="Checkout" v-model="model.permissions.services.checkout.access" />
			<app-input-toggle label="Library" v-model="model.permissions.services.library.access" />

		</app-content-box>

		<app-content-box title="Other access" icon="password" v-if="!isNew">

			<app-input-toggle label="Billing" v-model="model.permissions.other.billing.access" />
			<app-input-toggle label="Team" v-model="model.permissions.other.team.access" />
			<app-input-toggle label="Settings" v-model="model.permissions.other.settings.access" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { email, requiredIf } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			references: {
				types: []
			},
			emailMessages: {
				info: 'If the prospective team member already has a Cardboard Events account then you should use that account\'s e-mail address to link the convention with their profile.',
				added: 'This e-mail address is associated with a Cardboard Events account that is already part of the team.',
				exists: 'This e-mail address is already associated with an existing Cardboard Events account. They will be added as a team member.',
				register: 'A Cardboard Events login will be generated for this e-mail address and will be added as a team member. The login credentials will automatically be e-mailed to the user.'
			},
			model: {
				id: false,
				exists: false,
				used: false,
				email: '',
				firstname: '',
				lastname: '',
				badgeGenerate: 0,
				badgeType: 0,
				permissions: {
					convention: {
						announcements: {
							access: 0
						},
						badges: {
							access: 0
						}
					},
					services: {
						registration: {
							access: 0
						},
						schedule: {
							access: 0
						},
						checkout: {
							access: 0
						},
						library: {
							access: 0
						}
					},
					other: {
						billing: {
							access: 0
						},
						team: {
							access: 0
						},
						settings: {
							access: 0
						}
					}
				}
			}
		}

	},

	validations: {
		model: {
			email: {
				required: requiredIf('isNew'),
				email
			}
		}
	},

	computed: {

		badgeTypes: function() {

			var types = []

			this.$_.each(this.references.types, function(type) {

				types.push({
					value: type.id,
					text: type.name
				})

			})

			return types

		}

	},

	methods: {

		onExistsChange: function(e) {

			this.model.exists = e.exists
			this.model.used = e.used
			this.model.firstname = e.firstname || this.model.firstname
			this.model.lastname = e.lastname || this.model.lastname

		}

	}

}

</script>

<style scoped>

</style>